import { connect } from "react-redux";
import _ from "lodash";
import { compose } from "react-apollo";
import { createPath } from "../../connections/pathConnections";
import { withRouter } from "react-router-dom";
import CreatePage from "../create-page/CreatePage";

const mapStateToProps = (state, ownProps) => ({
  ...state.createPage,
  ...state.auth,
});
const mapDispatchToProps = (dispatch, ownProps) => ({
  editField: ({ field, value }) => {
    dispatch({ type: "EDIT_CREATE_PATH_FIELD", field, value });
  },
  createPath: ({ title, description }) => {
    const { createPath, history } = ownProps;
    dispatch({ type: "CREATING_PATH", title, description });
    createPath({ title, description }).then((res) => {
      dispatch({ type: "CREATE_PATH_SUCCESS" });
      history.push("/paths/" + _.get(res, "data.createPath.id"));
    });
  },
});

export default withRouter(
  compose(
    createPath,
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
  )(CreatePage)
);

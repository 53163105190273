import React from "react";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from "react-toastify";
import HeaderContainer from "../header/HeaderContainer";
import Main from "./Main";

const Skeleton = props => {
  return (
    <div className="skeleton">
      <Helmet>
        <title>Bifocl</title>
      </Helmet>
      <HeaderContainer />
      <Main />
      <ToastContainer position={toast.POSITION.TOP_CENTER} />
    </div>
  );
};

export default Skeleton;

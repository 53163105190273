import React from "react";
import { Route, Switch } from "react-router-dom";
import HomePageContainer from "../home-page/HomePageContainer";
import CreatePageContainer from "../create-page/CreatePageContainer";
import PathPageContainer from "../path-page/PathPageContainer";
import ProfilePageContainer from "../profile-page/ProfilePageContainer";
import AuthCallback from "../../auth/AuthCallback";
import { login } from "../../connections/userConnections";
import { compose } from "react-apollo";
import { store } from "../../store";
import CategoriesPageContainer from "../categories-page/CategoriesPageContainer";

const Main = (props) => {
  const { loginToServer } = props;

  if (
    !_.get(store.getState(), "auth.user.id") &&
    _.get(store.getState(), "auth.jwt")
  ) {
    loginToServer().then(({ data }) => {
      store.dispatch({ type: "SET_USER_ID", id: data.login.id });
    });
  }

  return (
    <main className="container main-page">
      <Switch>
        <Route exact path="/" component={HomePageContainer} />
        <Route path="/create" component={CreatePageContainer} />
        <Route
          path="/paths"
          component={() => (
            <Switch>
              <Route exact path="/paths" component={CategoriesPageContainer} />
              <Route
                path="/paths/:id"
                component={PathPageContainer}
                props={props}
              />
            </Switch>
          )}
        />
        <Route
          path="/user"
          component={() => (
            <Switch>
              <Route
                exact
                path="/user"
                component={() => <div>should route to current user</div>}
              />
              <Route path="/user/:id" component={ProfilePageContainer} />
            </Switch>
          )}
        />
        <Route
          path="/callback"
          render={(props) => (
            <AuthCallback loginToServer={loginToServer} {...props} />
          )}
        />
      </Switch>
    </main>
  );
};

export default compose(login)(Main);

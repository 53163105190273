import React from "react";
import Avatar from "../components/Avatar";
import H2 from "../components/H2";
import PathSection from "../components/PathSection";
import { LoadingScreen } from "../components/Loading";

const ProfilePage = (props) => {
  const { user, loading, error } = props;
  if (loading || !user) {
    if (error) {
      console.log(error, error.networkError);
    }
    return <LoadingScreen />;
  }

  const { name, avatarUrl, createdPaths, bookmarkedPaths } = user;

  return (
    <div className="profile-page">
      <div className="d-flex flex-column align-items-center">
        <Avatar avatarUrl={avatarUrl} large />
        <div className="py-4">
          <H2>{name}</H2>
        </div>
      </div>
      <div className="profile-page-path-section pt-4">
        <PathSection title="Created Paths" paths={createdPaths} />
        <PathSection title="Bookmarked Paths" paths={bookmarkedPaths} />
      </div>
    </div>
  );
};

export default ProfilePage;

import { graphql } from "react-apollo";
import gql from "graphql-tag";
import Fragments from "./fragments";
import _ from "lodash";
import update from "immutability-helper";

export const GET_PATH_QUERY = gql`
  query getPath($id: ID!) {
    path(id: $id) {
      ...FullPath
    }
  }
  ${Fragments.path.full}
`;

export const getPath = graphql(GET_PATH_QUERY, {
  options: (props) => ({
    variables: { id: _.get(props, "match.params.id") },
  }),
  props: ({ data: { loading, error, refetch, path } }) => ({
    loading,
    error,
    refetchPath: refetch,
    path,
  }),
});

export const GET_ALL_PATHS = gql`
  query getAllPaths {
    allPaths {
      ...MiniPath
    }
  }
  ${Fragments.path.mini}
`;

const ADD_RESOURCE_MUTATION = gql`
  mutation addResource(
    $pathId: ID!
    $sectionId: ID!
    $resource: ResourceInput!
    $isFromExtension: Boolean
  ) {
    addResource(
      pathId: $pathId
      sectionId: $sectionId
      resource: $resource
      isFromExtension: $isFromExtension
    ) {
      ...FullPath
    }
  }
  ${Fragments.path.full}
`;

export const addResourceFromExtension = graphql(ADD_RESOURCE_MUTATION, {
  props: ({ mutate }) => ({
    addResource: (pathId, resource) => {
      return mutate({
        variables: {
          pathId,
          resource,
          isFromExtension: true,
        },
      });
    },
  }),
});

export const addResource = graphql(ADD_RESOURCE_MUTATION, {
  props: ({ mutate }) => ({
    addResource: (path, sectionId, resource) => {
      const { sections } = path;
      const optimisticResource = _.clone(resource);
      optimisticResource.__typename = "Resource";
      optimisticResource.id = "2";
      optimisticResource.isDeleted = false;
      optimisticResource.previewUrl =
        "https://media.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.gif";
      const index = _.findIndex(sections, (sec) => sec.id === sectionId);
      const optimisticSections = update(sections, {
        [index]: {
          resources: { $push: [optimisticResource] },
          resourceIds: { $push: [optimisticResource.id] },
        },
      });

      return mutate({
        variables: { pathId: path.id, sectionId, resource },
        optimisticResponse: {
          __typename: "MUTATION",
          addResource: {
            __typename: "Path",
            ...path,
            sections: optimisticSections,
          },
        },
        update: (cache, { data: { addResource } }) => {
          cache.writeQuery({
            query: GET_PATH_QUERY,
            variables: {
              id: path.id,
            },
            data: { path: addResource },
          });
        },
      });
    },
  }),
});

const EDIT_RESOURCE_MUTATION = gql`
  mutation editResource($resourceId: ID!, $resourceChanges: ResourceInput) {
    editResource(resourceId: $resourceId, resourceChanges: $resourceChanges) {
      ...FullResource
    }
  }
  ${Fragments.resources.full}
`;

export const editResource = graphql(EDIT_RESOURCE_MUTATION, {
  props: ({ mutate }) => ({
    editResource: (resource) => {
      // const optimisticResource = _.clone(resource);
      // optimisticResource.__typename = "Resource";
      const resourceId = resource.id;
      delete resource["id"];
      // delete resource["previewUrl"];
      return mutate({
        variables: { resourceId, resourceChanges: resource },
        // optimisticResponse: {
        //   __typename: "MUTATION",
        //   editResource: {
        //     ...optimisticResource,
        //   },
        // },
      });
    },
  }),
});

const DELETE_RESOURCE_MUTATION = gql`
  mutation deleteResource($resourceId: ID!) {
    deleteResource(resourceId: $resourceId) {
      ...FullResource
    }
  }
  ${Fragments.resources.full}
`;

export const deleteResource = graphql(DELETE_RESOURCE_MUTATION, {
  props: ({ mutate }) => ({
    deleteResource: (resourceId) => {
      return mutate({
        variables: { resourceId },
      });
    },
  }),
});

const REORDER_RESOURCES_MUTATION = gql`
  mutation reorderResources(
    $pathId: ID!
    $sectionId: ID!
    $orderedResourceIds: [ID]!
  ) {
    reorderResources(
      pathId: $pathId
      sectionId: $sectionId
      orderedResourceIds: $orderedResourceIds
    ) {
      ...FullPath
    }
  }
  ${Fragments.path.full}
`;

export const reorderResources = graphql(REORDER_RESOURCES_MUTATION, {
  props: ({ mutate }) => ({
    reorderResources: (pathId, sectionId, orderedResourceIds) => {
      return mutate({
        variables: { pathId, sectionId, orderedResourceIds },
      });
    },
  }),
});

import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const Avatar = props => {
  const { avatarUrl, large, small } = props;

  const className = classnames("avatar", {
    large,
    small
  });

  return (
    <div className={className}>
      <img src={avatarUrl} />
    </div>
  );
};

Avatar.propTypes = {
  avatarUrl: PropTypes.string.isRequired,
  large: PropTypes.bool,
  small: PropTypes.bool
};

export default Avatar;

import gql from "graphql-tag";

const Fragments = {};

Fragments.resources = {
  full: gql`
    fragment FullResource on Resource {
      id
      title
      linkUrl
      description
      previewUrl
      isDeleted
      __typename
    }
  `,
};

Fragments.path = {
  mini: gql`
    fragment MiniPath on Path {
      id
      title
      isBookmarked
      creator {
        id
        name
        avatarUrl
        __typename
      }
      sections {
        id
        title
        __typename
      }
      isPublished
      __typename
    }
  `,
};

Fragments.user = {
  full: gql`
    fragment FullUser on User {
      id
      username
      name
      avatarUrl
      createdPaths {
        ...MiniPath
      }
      bookmarkedPaths {
        ...MiniPath
      }
      __typename
    }
    ${Fragments.path.mini}
  `,
};

Fragments.path.full = gql`
  fragment FullPath on Path {
    id
    title
    description
    isBookmarked
    bookmarkCount
    creator {
      id
      name
      avatarUrl
      __typename
    }
    sections {
      id
      title
      description
      resourceIds
      resources {
        ...FullResource
      }
    }
    isPublished
    __typename
  }
  ${Fragments.resources.full}
`;

export default Fragments;

import React from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import { FaUserSecret } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import P from "./P";
import H3 from "./H3";
import Avatar from "./Avatar";

const PathCard = (props) => {
  const {
    path: {
      id,
      title,
      creator: { avatarUrl, name },
      sections,
      isPublished,
    },
  } = props;

  const Section = (props) => {
    const { section } = props;
    return <li className="P resource-line mb-1">{section.title}</li>;
  };

  return (
    <React.Fragment>
      <Link to={`/paths/${id}`}>
        <div className="path-card">
          {isPublished ? null : (
            <div
              className="private-icon"
              data-tip="Path is private. Publish it to share with others!"
            >
              <FaUserSecret />
            </div>
          )}
          <H3>{title}</H3>
          <div className="d-flex pt-3 align-items-center">
            <div className="pr-2">
              <Avatar avatarUrl={avatarUrl} small />
            </div>
            <P gray>{name}</P>
          </div>
          <ul className="py-1">
            {_.map(_.slice(sections, 0, 3), (section, i) => {
              return <Section key={i} section={section} />;
            })}
            {sections.length > 3 ? (
              <li className="P">{`+ ${sections.length - 3} more`}</li>
            ) : null}
          </ul>
        </div>
      </Link>
      <ReactTooltip place="top" effect="solid" />
    </React.Fragment>
  );
};

export default PathCard;

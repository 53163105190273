import _ from "lodash";

const defaultState = {
  title: "",
  description: "",
  isAddResourceModalOpen: false,
  modalData: {
    link: "",
    linkTitle: "",
    linkDescription: "",
  },
};

const createPageReducer = (state = defaultState, action) => {
  const newState = _.cloneDeep(state);
  switch (action.type) {
    case "EDIT_CREATE_PATH_FIELD":
      newState[action.field] = action.value;
      break;
    case "CREATE_PATH_SUCCESS":
      newState.title = defaultState.title;
      newState.description = defaultState.description;
      break;
    default:
  }
  return newState;
};

export default createPageReducer;

import React from "react";
import _ from "lodash";
import { LoadingScreen } from "../components/Loading";
import PathSection from "../components/PathSection";

// export const CategorySections = ({ categories }) => {
//   return (
//     <div>
//       {_.map(
//         _.filter(categories, (section) => !_.isEmpty(section)),
//         (section, i) => {
//           return (
//             <PathSection
//               key={i}
//               title={PATH_CATEGORIES[section[0].category]}
//               paths={section}
//             />
//           );
//         }
//       )}
//     </div>
//   );
// };

const CategoryPage = (props) => {
  const { loading, allPaths } = props;
  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div className="categories-page">
      <PathSection title={"Paths"} paths={allPaths} />
      {/* <CategorySections categories={homePage} /> */}
    </div>
  );
};

export default CategoryPage;

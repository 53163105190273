import { connect } from "react-redux";
import { compose } from "react-apollo";
import { toast } from "react-toastify";
import {
  getPath,
  editPath,
  publishPath,
  bookmarkPath,
  unBookmarkPath,
  addSection,
  reorderSections,
} from "../../connections/pathConnections";
import {
  addResource,
  editResource,
  deleteResource,
  reorderResources,
} from "../../connections/resourceConnections";
import PathPage from "./PathPage";

const mapStateToProps = (state, ownProps) => ({
  ...state.pathPage,
  ...state.auth,
});
const mapDispatchToProps = (dispatch, ownProps) => ({
  openAddResourceModal: ({ sectionId }) => {
    dispatch({ type: "OPEN_ADD_RESOURCE_MODAL", sectionId });
  },
  closeAddResourceModal: () => {
    dispatch({ type: "CLOSE_ADD_RESOURCE_MODAL" });
  },
  editResourceModalField: ({ field, value }) => {
    dispatch({ type: "EDIT_ADD_RESOURCE_MODAL_FIELD", field, value });
  },
  openSectionModal: () => {
    dispatch({ type: "OPEN_SECTION_MODAL" });
  },
  closeSectionModal: () => {
    dispatch({ type: "CLOSE_SECTION_MODAL" });
  },
  editSectionModalField: ({ field, value }) => {
    dispatch({ type: "EDIT_SECTION_MODAL_FIELD", field, value });
  },
  addSection: ({ title, description }) => {
    const { path, addSection } = ownProps;
    dispatch({ type: "ADDING_SECTION" });
    addSection(path.id, { title, description }).then((res) => {
      dispatch({ type: "CLOSE_SECTION_MODAL" });
    });
  },
  addResource: ({ sectionId, title, linkUrl, description }) => {
    const { addResource, path } = ownProps;
    dispatch({ type: "SAVE_RESOURCE_START" });
    addResource(path, sectionId, { title, linkUrl, description })
      .then((res) => {
        toast.success("Added!");
        dispatch({ type: "SAVE_RESOURCE_SUCCESS" });
      })
      .catch((error) => {
        console.log(error.message)
        toast.error(error.toString());
        // TODO: Report what went wrong
        dispatch({ type: "OPEN_ADD_RESOURCE_MODAL", sectionId });
      });
    dispatch({ type: "CLOSE_ADD_RESOURCE_MODAL" });
  },
  editResource: ({ id, title, linkUrl, description, previewUrl }) => {
    dispatch({ type: "SAVE_RESOURCE_START" });
    ownProps
      .editResource({ id, title, description, linkUrl })
      .then((res) => {
        dispatch({ type: "SAVE_RESOURCE_SUCCESS" });
      })
      .catch((error) => {
        // TODO: Report what went wrong
        dispatch({ type: "OPEN_ADD_RESOURCE_MODAL" });
      });
    dispatch({ type: "CLOSE_ADD_RESOURCE_MODAL" });
  },
  openEditResourceModal: (resource) => {
    dispatch({ type: "OPEN_EDIT_RESOURCE_MODAL", resource });
  },
  deleteResource: (resourceId) => {
    const { deleteResource, refetchPath } = ownProps;
    if (
      confirm(
        "Deleting resource is irreversable. Are you sure you want to continue?"
      )
    ) {
      deleteResource(resourceId)
        .then((res) => {
          refetchPath();
          toast.success("Deleted");
        })
        .catch(console.log);
    }
  },
  openEditPathModal: () => {
    const { path } = ownProps;
    dispatch({ type: "OPEN_EDIT_PATH_MODAL", path });
  },
  closeEditPathModal: () => dispatch({ type: "CLOSE_EDIT_PATH_MODAL" }),
  onEditPathModalField: ({ field, value }) => {
    dispatch({ type: "EDIT_PATH_MODAL_FIELD", field, value });
  },
  editPath: (pathChanges) => {
    const { editPath, path } = ownProps;
    editPath(path, pathChanges)
      .then((res) => {
        dispatch({ type: "CLOSE_EDIT_PATH_MODAL" });
      })
      .catch(console.log);
  },
  saveSectionReorder: (sections) => {
    const { reorderSections, path } = ownProps;
    const orderedIds = _.map(sections, "id");
    const pathId = path.id;
    reorderSections(pathId, orderedIds);
  },
  saveCardReorder: (resources, sectionId) => {
    const { reorderResources, path } = ownProps;
    const orderedIds = _.map(resources, "id");
    const pathId = path.id;
    reorderResources(pathId, sectionId, orderedIds);
  },
  bookmarkPath: () => {
    const { bookmarkPath, path } = ownProps;
    bookmarkPath(path);
  },
  unBookmarkPath: () => {
    const { unBookmarkPath, path } = ownProps;
    unBookmarkPath(path);
  },
  publishPath: () => {
    const { path, publishPath } = ownProps;
    publishPath(path)
      .then((res) => {
        toast.success("Published!");
      })
      .catch(console.log);
  },
});

const PathPageContainer = compose(
  bookmarkPath,
  unBookmarkPath,
  getPath,
  editPath,
  addSection,
  addResource,
  editResource,
  deleteResource,
  publishPath,
  reorderSections,
  reorderResources,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PathPage);

export default PathPageContainer;

import _ from "lodash";

const defaultState = {
  showUserMenu: false
};

const headerReducer = (state = defaultState, action) => {
  const newState = _.cloneDeep(state);
  switch (action.type) {
    case "TOGGLE_USER_HEADER_MENU":
      newState.showUserMenu = !newState.showUserMenu;
      break;
    default:
  }
  return newState;
};

export default headerReducer;

import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Input from "../components/Input";
import Textarea from "../components/Textarea";

const CreatePathBox = (props) => {
  const { title, description, editField } = props;
  return (
    <div className="col-md-8 col-sm-12 create-path-box">
      <div className="create-path-top-section">
        <div className="d-flex">
          <div className="w-100">
            <Input
              value={title}
              onChange={(value) => editField({ field: "title", value })}
              placeholder="Path Name*"
            />
          </div>
        </div>
        <div className="my-4">
          <Textarea
            placeholder="Description"
            value={description}
            onChange={(value) => editField({ field: "description", value })}
          />
        </div>
      </div>
    </div>
  );
};

CreatePathBox.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  isAddResourceModalOpen: PropTypes.bool,
  openAddResourceModal: PropTypes.func,
};

export default CreatePathBox;

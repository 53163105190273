import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Header from "./Header";

const mapStateToProps = (state, ownProps) => ({
  ...state.auth,
  ...state.header
});
const mapDispatchToProps = (dispatch, ownProps) => ({
  toggleUserMenu: () => dispatch({ type: "TOGGLE_USER_HEADER_MENU" }),
  logout: () => {
    dispatch({ type: "LOGOUT" });
    dispatch({ type: "TOGGLE_USER_HEADER_MENU" });
  }
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Header)
);

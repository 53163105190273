import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { ITEM_TYPES } from "./DraggableItemTypes";
import ResourceCard from "./ResourceCard";

const DraggableResourceCard = (props) => {
  if (!props.resource) {
    return null;
  }

  const {
    resource: { id, isDeleted },
    index,
    moveCard,
    isPathOwner,
  } = props;

  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: ITEM_TYPES.RESOURCE,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get horizantal middle
      const hoverMiddleX =
        (hoverBoundingRect.left - hoverBoundingRect.right) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientX = clientOffset.x - hoverBoundingRect.right;
      // Only perform the move when the mouse has crossed half of the items width
      // When dragging left, only move when the cursor is below 50%
      // When dragging dragging, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type: ITEM_TYPES.RESOURCE, id, index },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  if (isPathOwner) {
    drag(drop(ref));
  }
  return (
    <div
      className="col-md-3 mt-3"
      ref={ref}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: isPathOwner ? "move" : "default",
      }}
    >
      <ResourceCard {...props} />
    </div>
  );
};
export default DraggableResourceCard;

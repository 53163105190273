import React from "react";
import PropTypes from "prop-types";
import Modal from "../components/Modal";
import Input from "../components/Input";
import Textarea from "../components/Textarea";

const AddResourceModal = (props) => {
  const {
    modalData,
    show,
    closeModal,
    onEditField,
    addResource,
    editResource,
    isInEditMode,
  } = props;
  const {
    resourceId,
    sectionId,
    link,
    linkTitle,
    linkDescription,
    previewUrl,
  } = modalData;

  const onSave = () => {
    isInEditMode
      ? editResource({
          id: resourceId,
          linkUrl: link,
          title: linkTitle,
          description: linkDescription,
        })
      : addResource({
          sectionId,
          linkUrl: link,
          title: linkTitle,
          description: linkDescription,
        });
  };

  return (
    <Modal
      header={isInEditMode ? "Edit Link" : "Add Link"}
      show={show}
      onClose={closeModal}
    >
      <div className="mt-1">
        {isInEditMode ? null : (
          <div className="mt-2">
            <Input
              value={link}
              placeholder="Link"
              onChange={(value) => onEditField({ field: "link", value })}
            />
          </div>
        )}
        <div className="mt-2">
          <Input
            value={linkTitle}
            placeholder="Link Title"
            onChange={(value) => onEditField({ field: "linkTitle", value })}
          />
        </div>
        <div className="mt-2">
          <Textarea
            value={linkDescription}
            placeholder="Enter description, notes, or insights"
            onChange={(value) =>
              onEditField({ field: "linkDescription", value })
            }
          />
        </div>
      </div>
      <div className="mt-3 d-flex justify-content-center">
        <button className="btn btn-primary px-4" onClick={onSave}>
          save
        </button>
      </div>
    </Modal>
  );
};

AddResourceModal.propTypes = {
  show: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  onEditField: PropTypes.func.isRequired,
  modalData: PropTypes.object,
};

export default AddResourceModal;

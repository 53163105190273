import React from "react";
import H4 from "../components/H4";
import P from "../components/P";
import grayLogo from "../../resources/logo_gray.png";
import { TiPencil } from "react-icons/ti";
import { FiTrash2 } from "react-icons/fi";

const ResourceCard = (props) => {
  const { resource, isPathOwner, onEdit, onDelete } = props;
  return (
    <div className="resource-card pb-1">
      {isPathOwner ? (
        <div className="admin-icons">
          <div className="pr-3" onClick={() => onEdit(resource)}>
            <TiPencil />
          </div>
          <div onClick={() => onDelete(resource.id)}>
            <FiTrash2 />
          </div>
        </div>
      ) : null}
      <a href={resource.linkUrl} target="_blank">
        <div className="resource-preview-div">
          <img
            className="w-100 resource-preview"
            // TODO change to preview of resource
            src={resource.previewUrl || grayLogo}
          />
        </div>
        <div className="resource-content pt-2 p-1">
          <div className="mb-1">
            <H4>{resource.title}</H4>
          </div>
          <P gray light>
            {resource.description}
          </P>
        </div>
      </a>
    </div>
  );
};

export default ResourceCard;

import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import CreatePathBox from "./CreatePathBox";
import H2 from "../components/H2";
import LoginRequiredModal from "../components/LoginRequiredModal";

const CreatePage = (props) => {
  const { createPath, title, description, user } = props;

  return (
    <React.Fragment>
      <div className="create-page">
        <H2>Create Path</H2>
        <CreatePathBox {...props} />
        <button
          className="create-btn btn btn-primary"
          disabled={!title}
          onClick={() => createPath({ title, description })}
        >
          Create
        </button>
      </div>
      <LoginRequiredModal
        show={!_.get(user, "id")}
        currentLocation={"/create"}
      />
    </React.Fragment>
  );
};

CreatePage.propTypes = {
  createPath: PropTypes.func,
  closeAddResourceModal: PropTypes.func,
};

export default CreatePage;

import { createStore, combineReducers } from "redux";
import {
  persistStore,
  persistReducer,
  persistCombineReducers,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import createPageReducer from "./UI/create-page/createPageReducer";
import pathPageReducer from "./UI/path-page/pathPageReducer";
import authReducer from "./auth/authReducer";
import headerReducer from "./UI/header/headerReducer";

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["createPage", "pathPage"],
};
const persistedReducer = persistCombineReducers(persistConfig, {
  createPage: createPageReducer,
  pathPage: pathPageReducer,
  auth: authReducer,
  header: headerReducer,
});

export const store = createStore(
  persistedReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export const persistor = persistStore(store);

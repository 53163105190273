import _ from "lodash";

const defaultState = {
  isAddResourceModalOpen: false,
  isEditPathModalOpen: false,
  isSectionModalOpen: false,
  isAddingResource: false,
  resourceModalData: {
    resourceId: null, // if there is an id, the modal is editing, not creating
    sectionId: null,
    link: "",
    linkTitle: "",
    linkDescription: "",
    previewUrl: "", // should only be completed if in edit mode
  },
  editPathModalData: {
    title: "",
    description: "",
  },
  sectionModalData: {
    title: "",
    description: "",
    isAddingSection: false,
  },
};

const pathPageReducer = (state = defaultState, action) => {
  const newState = _.cloneDeep(state);

  switch (action.type) {
    case "REHYDRATE":
      return action.payload.pathPage;
    case "EDIT_PATH_MODAL_FIELD":
      newState.editPathModalData[action.field] = action.value;
      break;
    case "OPEN_EDIT_PATH_MODAL":
      const { path } = action;
      newState.isEditPathModalOpen = true;
      newState.editPathModalData = {
        title: path.title,
        description: path.description,
      };
      break;
    case "CLOSE_EDIT_PATH_MODAL":
      newState.isEditPathModalOpen = false;
      newState.editPathModalData = defaultState.editPathModalData;
      break;
    case "EDIT_SECTION_MODAL_FIELD":
      newState.sectionModalData[action.field] = action.value;
      break;
    case "OPEN_SECTION_MODAL":
      newState.isSectionModalOpen = true;
      break;
    case "OPEN_EDIT_SECTION_MODAL":
      newState.isAddResourceModalOpen = true;
      newState.resourceModalData = {
        sectionId: _.get(action, "section.id"),
        title: _.get(action, "section.title"),
        description: _.get(action, "section.description"),
      };
      break;
    case "CLOSE_SECTION_MODAL":
      newState.isSectionModalOpen = false;
      newState.sectionModalData = defaultState.sectionModalData;
      break;
    case "EDIT_ADD_RESOURCE_MODAL_FIELD":
      newState.resourceModalData[action.field] = action.value;
      break;
    case "OPEN_ADD_RESOURCE_MODAL":
      newState.isAddResourceModalOpen = true;
      newState.resourceModalData.sectionId = action.sectionId;
      break;
    case "CLOSE_ADD_RESOURCE_MODAL":
      newState.isAddResourceModalOpen = false;
      newState.resourceModalData = defaultState.resourceModalData;
      break;
    case "OPEN_EDIT_RESOURCE_MODAL":
      const { title, linkUrl, description, previewUrl } = action.resource;
      newState.isAddResourceModalOpen = true;
      newState.resourceModalData = {
        resourceId: _.get(action, "resource.id"),
        link: linkUrl,
        linkTitle: title,
        linkDescription: description,
        previewUrl,
      };
      break;
    case "CLOSE_EDIT_RESOURCE_MODAL":
      newState.isAddResourceModalOpen = false;
      newState.resourceInEditMode = null;
      break;
    case "SAVE_RESOURCE_START":
      newState.isAddingResource = true;
      break;
    case "SAVE_RESOURCE_SUCCESS":
      newState.isAddingResource = false;
      newState.isAddResourceModalOpen = false;
      newState.resourceModalData = defaultState.resourceModalData;
      break;
    case "ADDING_SECTION":
      newState.sectionModalData.isAddingSection = true;
      break;
    default:
  }
  return newState;
};

export default pathPageReducer;

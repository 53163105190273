import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import Modal from "../components/Modal";
import Input from "../components/Input";
import Textarea from "../components/Textarea";

const EditPathModal = (props) => {
  const { modalData, show, closeModal, onEditField, onSave } = props;
  const { title, description } = modalData;

  return (
    <Modal header="Edit Path" show={show} onClose={closeModal}>
      <div className="mt-4">
        <Input
          value={title}
          onChange={(value) => onEditField({ field: "title", value })}
          placeholder="Path Name*"
        />
      </div>
      <div className="mt-2 mb-4">
        <Textarea
          placeholder="Description"
          value={description}
          onChange={(value) => onEditField({ field: "description", value })}
        />
      </div>
      <div className="mt-3 d-flex justify-content-center">
        <button
          className="btn btn-primary px-4"
          onClick={() => onSave({ title, description })}
        >
          save
        </button>
      </div>
    </Modal>
  );
};

EditPathModal.propTypes = {
  show: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  onEditField: PropTypes.func.isRequired,
  modalData: PropTypes.object,
};

export default EditPathModal;

import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { withSizes } from "react-sizes";
import H2 from "./H2";
import P from "./P";
import PathCard from "./PathCard";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";

class PathSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
    };
  }

  toggle(direction) {
    const { currentIndex } = this.state;
    if (direction === "left" && currentIndex > 0) {
      this.setState({ currentIndex: currentIndex - 1 });
    } else {
      this.setState({ currentIndex: currentIndex + 1 });
    }
  }

  renderLeftArrow() {
    const { currentIndex } = this.state;
    if (currentIndex > 0) {
      return (
        <div className="arrow left" onClick={() => this.toggle("left")}>
          <FiChevronLeft />
        </div>
      );
    }
    return null;
  }

  renderRightArrow() {
    const { paths, numOfCards } = this.props;
    const { currentIndex } = this.state;

    if (currentIndex + numOfCards < paths.length) {
      return (
        <div className="arrow right" onClick={() => this.toggle("right")}>
          <FiChevronRight />
        </div>
      );
    }
    return null;
  }

  render() {
    const { title, paths, numOfCards } = this.props;
    const { currentIndex } = this.state;
    if (!paths) {
      console.log("no paths");
      return null;
    }
    return (
      <div className="path-section d-flex flex-column">
        <div className="row col-md-12 ">
          <H2>{title}</H2>
        </div>
        <div className="row py-4 position-relative">
          {this.renderLeftArrow()}
          {paths.length > 0 ? (
            _.map(
              _.slice(paths, currentIndex, currentIndex + numOfCards),
              (path) => (
                <div
                  key={path.id}
                  className="col-lg-3 col-md-4 col-sm-6 col-12"
                >
                  <PathCard path={path} />
                </div>
              )
            )
          ) : (
            <div className="d-flex w-100 justify-content-center align-items-center">
              <P big gray light>
                No paths yet :(
              </P>
            </div>
          )}
          {this.renderRightArrow()}
        </div>
      </div>
    );
  }
}

PathSection.propTypes = {
  title: PropTypes.string.isRequired,
  paths: PropTypes.array,
  seeAllOption: PropTypes.bool,
};

const mapSizesToProps = ({ width }) => {
  let numOfCards;
  if (width < 576) {
    numOfCards = 1;
  } else if (width < 768) {
    numOfCards = 2;
  } else if (width < 992) {
    numOfCards = 3;
  } else {
    numOfCards = 4;
  }

  return { numOfCards };
};

export default withSizes(mapSizesToProps)(PathSection);

import React, { useCallback, useState, useEffect } from "react";
import _ from "lodash";
import update from "immutability-helper";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import H3 from "../components/H3";
import P from "../components/P";
import DraggableResourceCard from "./DraggableResourceCard";

const Section = ({
  i,
  isPathOwner,
  openEditResourceModal,
  openAddResourceModal,
  section,
  deleteResource,
  defaultOpen,
  saveCardReorder,
}) => {
  const { title, description, resources, resourceIds } = section;

  const [isOpen, setCollapsed] = useState(defaultOpen);
  const [orderedResources, setCardOrder] = useState(
    _.map(resourceIds, (id) => _.find(resources, (res) => res.id === id))
  );

  useEffect(() => {
    if (
      !_.isEqual(_.sortBy(resources, "id"), _.sortBy(orderedResources, "id"))
    ) {
      setCardOrder(
        _.map(resourceIds, (id) => _.find(resources, (res) => res.id === id))
      );
    }
  });

  const reorderCards = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = orderedResources[dragIndex];
      const newOrder = update(orderedResources, {
        $splice: [[dragIndex, 1], [hoverIndex, 0, dragCard]],
      });
      setCardOrder(newOrder);
      saveCardReorder(newOrder);
    },
    [orderedResources]
  );

  return (
    <div className="section pt-4">
      <div className="section-header pl-2">
        <button
          className="btn-no-dflt title pb-2 d-flex align-items-center"
          onClick={() => setCollapsed(!isOpen)}
        >
          <H3 className="mr-2">{`${i + 1}. ${title}`}</H3>
          {isOpen ? <MdExpandLess /> : <MdExpandMore />}
        </button>
        <P gray light>
          {description}
        </P>
      </div>
      <div className={isOpen ? "resources row py-4" : "resources row pb-4"}>
        {isPathOwner && isOpen ? (
          <div className="col-md-3 py-3">
            <button
              className="btn add-resource-button w-100"
              onClick={() => openAddResourceModal({ sectionId: section.id })}
            >
              + Add Link
            </button>
          </div>
        ) : null}
        {isOpen
          ? _.map(orderedResources, (resource, i) => (
              <DraggableResourceCard
                isPathOwner={isPathOwner}
                resource={resource}
                key={i}
                index={i}
                onEdit={(resource) => openEditResourceModal(resource)}
                onDelete={(resourceId) => deleteResource(resourceId)}
                moveCard={reorderCards}
              />
            ))
          : null}
      </div>
    </div>
  );
};

export default Section;

import React, { Component } from "react";
import _ from "lodash";
import Auth from "./Auth";
import { store } from "../store";

class AuthCallback extends Component {
  //Auth.handleAuthentication(props.history);
  componentDidMount() {
    const { history, loginToServer } = this.props;
    const handleAuthCallback = () => {
      loginToServer()
        .then(({ data }) => {
          const dispatch = _.get(store, "dispatch");
          if (dispatch) {
            dispatch({ type: "SET_USER_ID", id: data.login.id });
          }
        })
        .catch(error => {
          console.log(error);
        });
    };
    Auth.handleAuthentication(history, handleAuthCallback);
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "white"
        }}
      >
        Logging in...
      </div>
    );
  }
}

export default AuthCallback;
